import React from 'react';
import { graphql } from 'gatsby';

import TemplateDefault from './default';
import getParagraph from '../components/paragraphs/paragraph-loader';
import AtomTitle from '../components/atom/title';
import AtomDrupalContent from '../components/atom/drupal-content';
import SEO from '../components/seo';

export default function VideoPage({ data }) {
  const paragraph = getParagraph(data.nodePage.relationships?.paragraphs);

  return (
    <TemplateDefault title={data.nodePage.title}>
      <main className="tw-bg-anbg-cream">
        <section className="tw-relative tw-h-full tw-w-[100vw] tw-py-20 tw-px-4 xl:tw-ml-[235px] xl:tw-w-[calc(100vw-235px)] xl:tw-px-10 xl:tw-pt-24 2xl:tw-pt-32">
          <div className=" ">
            <div className="tw-mx-auto tw-w-full tw-w-full  tw-text-anbg-offblack md:tw-space-x-10 md:tw-px-6 xl:tw-flex 2xl:tw-space-x-20 ">
              <div className="tw-flex tw-flex-col ">
                <div className="tw-pb-8">
                  <AtomTitle title={data.nodePage.title} />
                </div>
                {data.nodePage.description.value && (
                  <AtomDrupalContent
                    content={data.nodePage.description.value}
                  />
                )}
              </div>
              {/*<div className="xl:tw-w-1/2">*/}
              {/*  <img src={image()} alt="" className="tw-w-full" />*/}
              {/*</div>*/}
            </div>
          </div>
        </section>

        <div className="tw-pb-16 xl:tw-ml-[235px]">{paragraph}</div>
      </main>
    </TemplateDefault>
  );
}

export const query = graphql`
  query ($drupalId: String!) {
    nodePage: nodeHubResourceVideo(drupal_id: { eq: $drupalId }) {
      title
      description: field_anbg_lh_description {
        value
      }
      relationships {
        paragraphs: field_embed_video {
          type: __typename

          ...ParagraphEmbedvideo
        }
      }
    }
  }
`;

export function Head({ data }) {
  return <SEO title={data.nodePage.title} />;
}
